var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('report',{attrs:{"title":_vm.$route.name,"parameters":_vm.parameters,"loading":_vm.loading},on:{"refresh":function($event){return _vm.getReportData()},"exportReport":_vm.exportReport},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"5","offset":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rptData,"search":_vm.filter,"items-per-page":200},scopedSlots:_vm._u([{key:"item.rentedUnits",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber( item.rentedUnits, 0 ))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney( item.total ))+" ")]}},{key:"item.payments",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney( item.payments ))+" ")]}},{key:"item.paymentsPerc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber( item.paymentsPerc, 2 ))+"% ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [(items.length > 0)?_c('tr',{staticClass:"totals"},_vm._l((headers),function(col,i){return _c('td',{key:i,class:col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'},[_vm._v(" "+_vm._s(_vm.summarize( col, i ))+" ")])}),0):_vm._e()]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }